(function () {
  'use strict';

  /* @ngdoc object
   * @name phases.inscription
   * @description
   *
   */
  angular
    .module('neo.home.phases.inscription', []);
}());
